class GoogleTagManager extends Component {

    /**
     * @type {{id: number, name: string}[]}
     */
    consentTypes = []

    constructor(el) {
        super(el);

        this.consentTypes = JSON.parse(this.$el.dataset.consentTypes);
        this.registerEventListeners();
    }

    registerEventListeners() {
        EventBus.getInstance().addEventListener('homeSliderClicked', (e) => this.sendPromotionClick(e.detail.name, e.detail.id, 'HOMEPAGE'));
        EventBus.getInstance().addEventListener('cookiesConsentStored', (e) => this.onCookiesConsentStored(e.detail));
    }

    translateBoolToConsent(bool) {
        return bool ? 'granted' : 'denied';
    }

    /**
     * @param {{consent: number}} ev
     */
    onCookiesConsentStored(ev) {
        const consents = {};
        this.consentTypes.forEach(t => consents[t.name] = this.translateBoolToConsent(t.id === (t.id & ev.consent)));
        this.sendEvent(this.toArguments('consent', 'update', consents));
        this.sendEvent({event: 'consent_updated'})
    }

    toArguments() {
        return arguments;
    }

    sendPromotionClick(name, id, location) {
        const event = {
            'event': 'ecc_promotion_click',
            'ecommerce': {
                'promoClick': {
                    'promotions': [
                        {
                            'id': id,
                            'name': name,
                            'position': location
                        }]
                }
            },
        };
        this.sendEvent({ecommerce: null});
        this.sendEvent(event);
    }

    sendEvent(event) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(event);
    }

}
